
import cz from '../lang/cz.json';
import da from '../lang/da.json';
import de from '../lang/de.json';
import en from '../lang/en.json';
import es from '../lang/es.json';
import fi from '../lang/fi.json';
import fr from '../lang/fr.json';
import hu from '../lang/hu.json';
import it from '../lang/it.json';
import ja from '../lang/ja.json';
import ko from '../lang/ko.json';
import nb from '../lang/nb.json';
import pl from '../lang/pl.json';
import ptb from '../lang/ptb.json';
import ru from '../lang/ru.json';
import sk from '../lang/sk.json';
import sv from '../lang/sv.json';
import th from '../lang/th.json';
import tr from '../lang/tr.json';
import zhs from '../lang/zhs.json';

function LangController() {


    let locale = '';
    const currentURL = new URL(window.location.href);
    const pathnameSegments = currentURL.pathname.split('/');
    for (const segment of pathnameSegments) {
        if (segment.includes('-') && segment !== 'int') {
            locale = segment.split('-')[0];
            break;
        }
        else {
            locale = 'en';
        }
    }

    locale = locale.toLocaleLowerCase(); //getCodeOnly();
    let lang;
    switch (locale) {
        case 'cs':
            lang = cz;
            break;
        case 'da':
            lang = da;
            break;
        case 'de':
            lang = de;
            break;
        case 'en':
            lang = en;
            break;
        case 'es':
            lang = es;
            break;
        case 'fi':
            lang = fi;
            break;
        case 'fr':
            lang = fr;
            break;
        case 'hu':
            lang = hu;
            break;
        case 'it':
            lang = it;
            break;
        case 'ja':
            lang = ja;
            break;
        case 'ko':
            lang = ko;
            break;
        case 'nb':
            lang = nb;
            break;
        case 'pl':
            lang = pl;
            break;
        case 'pt':
            lang = ptb;
            break;
        case 'ru':
            lang = en;
            break;
        case 'sk':
            lang = sk;
            break;
        case 'sv':
            lang = sv;
            break;
        case 'th':
            lang = th;
            break;
        case 'tr':
            lang = tr;
            break;
        case 'zh':
            lang = zhs;
            break;
        default:
            lang = en;
    }
    return lang;
}

function getCodeOnly() {
    return navigator.language.split('-')[0];
}

export default LangController;