import { Slider, styled, ToggleButton, ToggleButtonGroup, Tooltip, tooltipClasses, TooltipProps, withStyles } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DeviceConfigService from "../../../Services/current-device-config.service";
import dataService from '../../../Services/data.service';
import Chart from '../chart/chart';
import Pagination from '../pagination/pagination';
import './dashboard.scss';
export interface IDashboardProps { };

function Dashboard() {
    const data = dataService.getData().data;
    const constant: any = dataService.getData().constant;
    const [fullWidth] = [true];
    const [industry, setIndustry] = useState('pneumaticToElectricMvi');
    const [selectedContry, setSelectedContry] = useState('Null');
    const [numberOfAirTools, setNumberOfTool] = useState<number>(501);
    const [numberOfElectricTools, setNumberOfElectricTool] = useState<number>(317);
    const [airToElectric, setAirToElectric] = useState<number>(75);
    const [numberOfToolsController, setNumberOfToolsController] = useState<number>(4);
    const [toolsWithInteratedController, setToolsWithInteratedController] = useState<number>(50);
    const [preventiveMaintenance, setNumberOfPreventiveMaintenance] = useState<number>(818);
    const [maxLimitpreventiveMaintenance, setMaxLimitOfPreventiveMaintenance] = useState<number>(818);
    const intl = useIntl();
    const [saving, setSaving] = useState({
        totalSaving: '0',
        savingInTon: '0',
        carbonPricing: '0',
        annualElectricityInEuro: '0'
    });

    const [currentPage, setCurrentPage] = useState(0);
    // const [debouncedannualSaving, setDebouncedannualSaving] = useState<number | string | Array<number | string>>();
    //useEffect(() => {
    //    dispatch(fetchData());
    //}, [dispatch]);

    useEffect(() => {
        if (data) {

            DeviceConfigService.getConfig().then(x => {

                let defaultCountry = getCountryFromURL();
                //let defaultCountry = 'US';
                if (data.countries.find(item => item.code === defaultCountry)) {
                    //defaultCountry = x.country_code;
                }
                else if (data.countries.find(item => item.code === x.country_code)) {
                    defaultCountry = x.country_code;
                }
                else {
                    defaultCountry = 'US';
                }
                setSelectedContry(defaultCountry);
                const saving = formula(numberOfAirTools, numberOfElectricTools, airToElectric, numberOfToolsController, toolsWithInteratedController, constant[industry], constant.countries[x.country_code], preventiveMaintenance);
                setSaving(saving);


            }).catch(x => {
                const defaultCountry: any = 'US';
                setSelectedContry(defaultCountry);
                const saving = formula(numberOfAirTools, numberOfElectricTools, airToElectric, numberOfToolsController, toolsWithInteratedController, constant[industry], constant.countries[defaultCountry], preventiveMaintenance);
                setSaving(saving);
            });
        }
    }, [data]);

    function getCountryFromURL() {
        let conCode = "";
        const currentURL = new URL(window.location.href);
        const pathnameSegments = currentURL.pathname.split('/');
        for (const segment of pathnameSegments) {
            if (segment.includes('-') && segment !== 'int') {
                conCode = segment.split('-')[1];
                break;
            }
            else {
                conCode = '';
            }
        }
        return conCode.toUpperCase();
    }

    const handleCountryChange = (
        _event: React.SyntheticEvent,
        value: any,
    ) => {
        setSelectedContry(value);
        if (value) {
            const saving = formula(numberOfAirTools, numberOfElectricTools, airToElectric, numberOfToolsController, toolsWithInteratedController, constant[industry], constant.countries[value.code], preventiveMaintenance);
            setSelectedContry(value.code);
            setSaving(saving);
        }
    };

    const handleIndustryChange = (
        _event: React.MouseEvent<HTMLElement>,
        newIndustry: string,
    ) => {
        if (newIndustry) {
            setIndustry(newIndustry);
            setSaving(formula(numberOfAirTools, numberOfElectricTools, airToElectric, numberOfToolsController, toolsWithInteratedController, constant[newIndustry], constant.countries[selectedContry], preventiveMaintenance));
        }
    };

    const handleNumberOfToolsSliderChange = (_event: Event, newValue: number | number[]) => {
        setNumberOfTool(newValue as number);
        const total = numberOfElectricTools + Number(newValue);
        setMaxLimitOfPreventiveMaintenance(total);
        setNumberOfPreventiveMaintenance(total as number)
        setSaving(formula(newValue as number, numberOfElectricTools, airToElectric, numberOfToolsController, toolsWithInteratedController, constant[industry], constant.countries[selectedContry], preventiveMaintenance));
    };

    const handleNumberOfElectricToolsSliderChange = (_event: Event, newValue: number | number[]) => {
        setNumberOfElectricTool(newValue as number);
        const total = numberOfAirTools + Number(newValue);
        setMaxLimitOfPreventiveMaintenance(total);
        setNumberOfPreventiveMaintenance(total as number)
        setSaving(formula(numberOfAirTools, newValue as number, airToElectric, numberOfToolsController, toolsWithInteratedController, constant[industry], constant.countries[selectedContry], preventiveMaintenance));
    };

    const handleAirToElectricSliderChange = (_event: Event, newValue: number | number[]) => {
        setAirToElectric(newValue as number);
        setSaving(formula(numberOfAirTools, numberOfElectricTools, newValue as number, numberOfToolsController, toolsWithInteratedController, constant[industry], constant.countries[selectedContry], preventiveMaintenance));
    };

    const handleNumberOfToolsControllerSliderChange = (_event: Event, newValue: number | number[]) => {
        setNumberOfToolsController(newValue as number);
        setSaving(formula(numberOfAirTools, numberOfElectricTools, airToElectric, newValue as number, toolsWithInteratedController, constant[industry], constant.countries[selectedContry], preventiveMaintenance));
    };

    const handleToolsWithInteratedControllerSliderChange = (_event: Event, newValue: number | number[]) => {
        setToolsWithInteratedController(newValue as number);
        setSaving(formula(numberOfAirTools, numberOfElectricTools, airToElectric, numberOfToolsController, newValue as number, constant[industry], constant.countries[selectedContry], preventiveMaintenance));
    };

    const handleNumberOfPreventiveMaintenaceSliderChange = (_event: Event, newValue: number | number[]) => {
        setNumberOfPreventiveMaintenance(newValue as number);
        setSaving(formula(numberOfAirTools, numberOfElectricTools, airToElectric, numberOfToolsController, toolsWithInteratedController, constant[industry], constant.countries[selectedContry], newValue as number));
    };


    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[5],
            fontSize: 14,
        },
    }));

    return (
        <div className="dashboard">
            <div className='left-panel'>
                <div className='left-panel-container'>

                    <div className='header'>
                        <img className='co2-logo' src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/co2-logo.svg" alt='CO2 Calculator' />
                    </div>
                    <div className='description'>
                        {/* <FormattedMessage
                        id="message"
                        defaultMessage="Welcome {username}"
                        values={
                            {
                                username: 'sid',
                            }
                        }/> */}
                        <FormattedMessage
                            id="label.header"
                            defaultMessage="Discover how you can transform your industry for a more sustainable future. Here is your chance to make a difference. Calculate your impact!"
                        />
                    </div>

                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <FormattedMessage
                                            id="label.factory_location"
                                            defaultMessage="Factory location"
                                        />
                                    </td>
                                    <td>
                                        {data.countries ?
                                            <Autocomplete
                                                id="country-dropdown"
                                                options={data?.countries}
                                                fullWidth={fullWidth}
                                                disableClearable={true}
                                                onChange={handleCountryChange}
                                                value={data?.countries.find((x: any) => x.code === selectedContry) ?? undefined}
                                                renderInput={(params) => <TextField {...params} label={<FormattedMessage id="label.country"
                                                    defaultMessage="Country" />} />}
                                            />
                                            : null}
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <FormattedMessage
                                            id="label.industry"
                                            defaultMessage="Industry"
                                        />
                                    </td>
                                    <td>
                                        <ToggleButtonGroup
                                            fullWidth={fullWidth}
                                            color="primary"
                                            value={industry}
                                            exclusive
                                            onChange={handleIndustryChange}
                                            aria-label="Platform"
                                            className='toggle-button'
                                        >
                                            {/* <LightTooltip title={intl.formatMessage({ id: "label.automotive_industry_tooltip", defaultMessage: "The automotive industry refers for example to automotive tiers, powertrain, line and machine tool builders, final assembly." })}>
                                            <ToggleButton value="pneumaticToElectricMvi" fullWidth={fullWidth}
                                                className={industry === 'pneumaticToElectricMvi' ? 'active-industry' : ''}>
                                                <FormattedMessage
                                                    id="label.automotive_industry"
                                                    defaultMessage="Automotive industry"
                                                />
                                            </ToggleButton>
                                        </LightTooltip>
                                        <LightTooltip title={intl.formatMessage({ id: "label.general_industry_tooltip", defaultMessage: "General industries refer for example to the segments of home appliances, recreational vehicles such as ATV, heavy equipment such as tractors and excavators, smartphones and other mobile devices, trains, aircrafts, etc." })}>
                                            <ToggleButton value="pneumaticToElectricGi" fullWidth={fullWidth}
                                                className={industry === 'pneumaticToElectricGi' ? 'active-industry' : ''}>
                                                <FormattedMessage
                                                    id="label.general_industry"
                                                    defaultMessage="General industry"
                                                />
                                            </ToggleButton>
                                        </LightTooltip> */}
                                            <Tooltip title={intl.formatMessage({ id: "label.automotive_industry_tooltip", defaultMessage: "The automotive industry refers for example to automotive tiers, powertrain, line and machine tool builders, final assembly." })}>
                                                <ToggleButton value="pneumaticToElectricMvi" fullWidth={fullWidth}
                                                    className={industry === 'pneumaticToElectricMvi' ? 'active-industry' : ''}>
                                                    <FormattedMessage
                                                        id="label.automotive_industry"
                                                        defaultMessage="Automotive industry"
                                                    />
                                                    {/* <div className="annual-saving-value-hover1 wrapper-tooltip-message1">
                                                        {intl.formatMessage({ id: "label.automotive_industry_tooltip", defaultMessage: "The automotive industry refers for example to automotive tiers, powertrain, line and machine tool builders, final assembly." })}
                                                    </div> */}
                                                </ToggleButton>
                                            </Tooltip>

                                            <Tooltip title={intl.formatMessage({ id: "label.general_industry_tooltip", defaultMessage: "General industries refer for example to the segments of home appliances, recreational vehicles such as ATV, heavy equipment such as tractors and excavators, smartphones and other mobile devices, trains, aircrafts, etc." })}>
                                                <ToggleButton value="pneumaticToElectricGi" fullWidth={fullWidth}
                                                    className={industry === 'pneumaticToElectricGi' ? 'active-industry' : ''}>
                                                    <FormattedMessage
                                                        id="label.general_industry"
                                                        defaultMessage="General industry"
                                                    />
                                                    {/* <div className="annual-saving-value-hover1 wrapper-tooltip-message1">
                                                    {intl.formatMessage({ id: "label.general_industry_tooltip", defaultMessage: "General industries refer for example to the segments of home appliances, recreational vehicles such as ATV, heavy equipment such as tractors and excavators, smartphones and other mobile devices, trains, aircrafts, etc." })}
                                                </div> */}
                                                </ToggleButton>
                                            </Tooltip>
                                        </ToggleButtonGroup></td>
                                </tr>
                                <tr>
                                    <td ><img src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/number-of-air-tools.svg" alt="Icon for number of air tools" /></td>
                                    <td>

                                        <FormattedMessage
                                            id="label.number_of_air_tools"
                                            defaultMessage="Number of air tools"
                                        />

                                    </td>
                                    <td className="slider-with-percentage ">
                                        <Slider
                                            sx={{ color: '#0099cc' }}
                                            value={typeof numberOfAirTools === 'number' ? numberOfAirTools : 0}
                                            onChange={handleNumberOfToolsSliderChange}
                                            aria-labelledby="input-slider"
                                            max={2500}
                                        />
                                        <div className="percentage-in-line">{numberOfAirTools}</div>
                                    </td>
                                    <td className="percentage-in-table">{numberOfAirTools}</td>
                                </tr>
                                <tr>
                                    <td><img src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/air-to-electric.svg" alt="Icon for number of electric tools" /></td>
                                    <td>

                                        <FormattedMessage
                                            id="label.number_of_electric_tools"
                                            defaultMessage="Number of electric tools"
                                        />

                                    </td>
                                    <td className="slider-with-percentage">
                                        <Slider
                                            sx={{ color: '#0099cc' }}
                                            value={typeof numberOfElectricTools === 'number' ? numberOfElectricTools : 0}
                                            onChange={handleNumberOfElectricToolsSliderChange}
                                            aria-labelledby="input-slider"
                                            max={2500}
                                        />
                                        <div className="percentage-in-line">{numberOfElectricTools}</div>
                                    </td>
                                    <td className="percentage-in-table">{numberOfElectricTools}</td>
                                </tr>
                                <tr>
                                    <td><img src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/number-of-electric-tools.svg" alt="Icon for air to electric" /></td>
                                    <td>

                                        <FormattedMessage
                                            id="label.air_electric"
                                            defaultMessage="Air to electric"
                                        />

                                    </td>
                                    <td className="slider-with-percentage">
                                        <Slider
                                            sx={{ color: '#008C95' }}
                                            value={typeof airToElectric === 'number' ? airToElectric : 0}
                                            onChange={handleAirToElectricSliderChange}
                                            aria-labelledby="input-slider"
                                        />
                                        <div className="percentage-in-line">{airToElectric} %</div>
                                    </td>
                                    <td className="percentage-in-table">{airToElectric} %</td>
                                </tr>
                                <tr>
                                    <td><img src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/number-of-tools-and-controller.svg" alt="Icon for number of tools / controller" /></td>
                                    <td>

                                        <FormattedMessage
                                            id="label.number_of_tools_controller"
                                            defaultMessage="Number of tools / controller"
                                        />

                                    </td>
                                    <td className="slider-with-percentage">
                                        <Slider
                                            sx={{ color: '#43B02A' }}
                                            value={typeof numberOfToolsController === 'number' ? numberOfToolsController : 0}
                                            onChange={handleNumberOfToolsControllerSliderChange}
                                            aria-labelledby="input-slider"
                                            max={6}
                                        />
                                        <div className="percentage-in-line">{numberOfToolsController}</div>
                                    </td>
                                    <td className="percentage-in-table">{numberOfToolsController}</td>
                                </tr>
                                <tr>
                                    <td><img src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/tools-with-interated-controller.svg" alt="Icon for tools with integrated controller" /></td>
                                    <td >

                                        <FormattedMessage
                                            id="label.tools_with_integrated_controller"
                                            defaultMessage="Tools with integrated controller"
                                        />

                                    </td>
                                    <td className="slider-with-percentage">
                                        <Slider
                                            sx={{ color: '#8DC8E8' }}
                                            value={typeof toolsWithInteratedController === 'number' ? toolsWithInteratedController : 0}
                                            onChange={handleToolsWithInteratedControllerSliderChange}
                                            aria-labelledby="input-slider"
                                        />
                                        <div className="percentage-in-line">{toolsWithInteratedController} %</div>
                                    </td>
                                    <td className="percentage-in-table">{toolsWithInteratedController} %</td>
                                </tr>

                                <tr>
                                    <td ><img src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/preventive_maintenance.svg" alt="Icon for preventive maintenance" /></td>
                                    <td>

                                        <FormattedMessage
                                            id="label.preventive_tools"
                                            defaultMessage="Preventive Maintenance"
                                        />

                                    </td>
                                    <td className="slider-with-percentage ">
                                        <Slider
                                            sx={{ color: '#0066B3' }}
                                            value={typeof preventiveMaintenance === 'number' ? preventiveMaintenance : 0}
                                            onChange={handleNumberOfPreventiveMaintenaceSliderChange}
                                            aria-labelledby="input-slider"
                                            max={maxLimitpreventiveMaintenance}
                                        />
                                        <div className="percentage-in-line">{preventiveMaintenance}</div>
                                    </td>
                                    <td className="percentage-in-table">{preventiveMaintenance}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='right-panel'>
                <div className='right-panel-container'>
                    <Chart data={saving} currentPage={currentPage} />
                    <Pagination onChange={(key: number) => setCurrentPage(key)} />
                </div>
            </div>
        </div>
    )


}
export default Dashboard;

function formula(numberOfAirTools: number, numberOfElectricTools: number, airToElectric: number, numberOfToolsControllers: number, integratedController: number, industryConstant: any, countryConstant: any, numberOfPreventiveMaintenanceTools: number) {
    const airToolFrom = (numberOfAirTools * industryConstant.energyConsumptionPnematicTool);
    const electricFrom = (numberOfElectricTools * (industryConstant.energyConsumptionElectricTool + industryConstant.energyConsumptionController));
    // const totalFrom = airToolFrom + electricFrom;

    const savingFrompreventiveMaintenance = (-industryConstant.preventiveMaintenanceTools * numberOfPreventiveMaintenanceTools) // 1000;

    const airToElectricFist = ((numberOfAirTools * (airToElectric / 100) * industryConstant.energyConsumptionElectricTool) + (((numberOfAirTools * (airToElectric / 100)) / numberOfToolsControllers) * industryConstant.energyConsumptionController));
    const airToElectricRemaining = (numberOfAirTools * ((100 - airToElectric) / 100) * industryConstant.energyConsumptionPnematicTool);
    const totalAirToElectric = (airToElectricFist === Infinity || NaN ? 0 : airToElectricFist) + airToElectricRemaining;

    const electricToBatteryFirst = (numberOfElectricTools * (integratedController / 100) * industryConstant.energyConsumptionIxbTool);
    const electricToBatteryRemaining = (numberOfElectricTools * ((100 - integratedController) / 100) * (industryConstant.energyConsumptionElectricTool + industryConstant.energyConsumptionController));
    const totalElectricToBattery = electricToBatteryFirst + electricToBatteryRemaining;
    const energyCosumptionControllerFrom = numberOfAirTools * (airToElectric / 100) * industryConstant.energyConsumptionController;
    const energyCosumptionControllerTo = ((numberOfAirTools * (airToElectric / 100)) / numberOfToolsControllers) * industryConstant.energyConsumptionController;
    const savingFromController = (energyCosumptionControllerTo === Infinity ? 0 : energyCosumptionControllerTo) - energyCosumptionControllerFrom;

    const savingAirToElectric = totalAirToElectric - airToolFrom;
    const savingElectricToElectric = totalElectricToBattery - electricFrom;

    // const totalRatioCalculate = savingAirToElectric + savingElectricToElectric + savingFromController;

    const totalSavingInDeciaml = savingAirToElectric + savingElectricToElectric;
    const totalSavingRatio = (savingAirToElectric * countryConstant.efFactor) + (savingElectricToElectric * countryConstant.efFactor) + savingFrompreventiveMaintenance;
    const airToElectricRatio = Math.trunc(((savingAirToElectric * countryConstant.efFactor) / totalSavingRatio) * 100);
    const electricToElectricRatio = Math.trunc(((savingElectricToElectric * countryConstant.efFactor) / totalSavingRatio) * 100);
    const savingFromControllerRatio = Math.trunc(((savingFromController * countryConstant.efFactor) / totalSavingRatio) * 100);
    const savingFromPreventiveMaintenanceRatio = Math.trunc((savingFrompreventiveMaintenance / totalSavingRatio) * 100);

    const totalSaving = Math.trunc(totalSavingInDeciaml);
    const savingInTonInDecimal = ((totalSavingInDeciaml * countryConstant.efFactor) / 1000) + (savingFrompreventiveMaintenance / 1000);
    const savingInTon = Math.trunc(savingInTonInDecimal);
    const carbonPricing = Math.trunc(savingInTonInDecimal * 100);
    const annualElectricityInEuro = Math.trunc((totalSavingInDeciaml + savingFrompreventiveMaintenance) * countryConstant.electricityCost);
    return {
        totalSaving: convertToK(totalSaving), savingInTon: convertToK(savingInTon),
        carbonPricing: convertToK(carbonPricing), annualElectricityInEuro: convertToK(annualElectricityInEuro),
        airToElectricRatio, electricToElectricRatio, savingFromControllerRatio, savingFromPreventiveMaintenanceRatio,
        airToElectric: industryConstant.airToElectric,
        electricToElectric: industryConstant.electricToElectric,
        controller: industryConstant.controller,
    };
}

function convertToK(inputValue: number): any {
    if (!isFinite(inputValue)) {
        return '0';
    }
    if (Math.abs(inputValue) > 99999) {
        return (inputValue / 1000).toFixed(1) + "k";
    }
    return inputValue.toString();
}