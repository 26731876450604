import Co2Components from './co2/co2components/co2components';


function App() {
  return ( 
    <Co2Components />
  );
}

export default App;
