
import constant from '../assets/data/constant-values.json';
import cz from '../assets/data/czCon.json';
import da from '../assets/data/daCon.json';
import de from '../assets/data/deCon.json';
import en from '../assets/data/enCon.json';
import es from '../assets/data/esCon.json';
import fi from '../assets/data/fiCon.json';
import fr from '../assets/data/frCon.json';
import hu from '../assets/data/huCon.json';
import it from '../assets/data/itCon.json';
import ja from '../assets/data/jaCon.json';
import ko from '../assets/data/koCon.json';
import nb from '../assets/data/nbCon.json';
import pl from '../assets/data/plCon.json';
import pt from '../assets/data/ptbCon.json';
import ru from '../assets/data/ruCon.json';
import sk from '../assets/data/skCon.json';
import sv from '../assets/data/svCon.json';
import th from '../assets/data/thCon.json';
import tr from '../assets/data/trCon.json';
import zh from '../assets/data/zhsCon.json';

export default {

  getData() {
    const code = this.getLangCode();
    let fileName = this.getfileName(code);
    const object = { ...fileName, ...constant, label: null };
    return object;
  },

  getLangCode() {
    let getDataCode = "";
    const currentURL = new URL(window.location.href);
    const pathnameSegments = currentURL.pathname.split('/');
    for (const segment of pathnameSegments) {
      if (segment.includes('-') && segment !== 'int') {
        getDataCode = segment.split('-')[0];
        break;
      }
      else {
        getDataCode = 'en';
      }
    }
    return getDataCode;
  },

  getfileName(code: string) {
    let fileName;
    switch (code) {
      case 'cs':
        fileName = cz;
        break;
      case 'da':
        fileName = da;
        break;
      case 'de':
        fileName = de;
        break;
      case 'en':
        fileName = en;
        break;
      case 'es':
        fileName = es;
        break;
      case 'fi':
        fileName = fi;
        break;
      case 'fr':
        fileName = fr;
        break;
      case 'hu':
        fileName = hu;
        break;
      case 'it':
        fileName = it;
        break;
      case 'ja':
        fileName = ja;
        break;
      case 'ko':
        fileName = ko;
        break;
      case 'nb':
        fileName = nb;
        break;
      case 'pl':
        fileName = pl;
        break;
      case 'pt':
        fileName = pt;
        break;
      case 'ru':
        fileName = en;
        break;
      case 'sk':
        fileName = sk;
        break;
      case 'sv':
        fileName = sv;
        break;
      case 'th':
        fileName = th;
        break;
      case 'tr':
        fileName = tr;
        break;
      case 'zh':
        fileName = zh;
        break;
      default:
        fileName = en;
    }
    return fileName;
  }
}