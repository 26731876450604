import { HighchartsReact } from 'highcharts-react-official';
import Highcharts, { PlotSeriesDataLabelsOptions, PlotSeriesOptions } from 'highcharts'
import './chart.scss';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface CustomPoint extends Highcharts.Point {
  point: any;
  key: string;
  color: string;
  y: number;
}



function Chart({ data, currentPage }: any) {

  const [isPageChange, setIsPageChange] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setIsPageChange(true);
  }, [currentPage]);

  useEffect(() => {
    setIsPageChange(false);
  }, [data]);



  function getAnnualSaving() {
    let label = "label.mwh"
    if (data && data?.totalSaving.toString().includes("k")) {
      label = "label.kwh"
    }
    return `${getAnnualSavingHeader()}
            <div class="annual-saving-value" >
          ${data?.totalSaving}
          </div> 
          
          ${getAnnualSavingUnit(label, "Mwh")}`;
  }

  function getAnnualSavingInTon() {
    return `${getAnnualSavingHeader()}
        <div class="annual-saving-value" >
            ${data?.savingInTon}
            </div>
            ${getAnnualSavingUnit("label.tn_co2e", "tn CO2e")}`;
  }

  function getAnnualSavingHeader() {
    // return `<div class="annual-saving-header elipsiscss" title=${intl.formatMessage({ id: "label.annual_saving", defaultMessage: "Annual saving" })}>
    //   ${intl.formatMessage({ id: "label.annual_saving", defaultMessage: "Annual saving" })} 
    //   </div>`;

    return `<div class="wrapper-tooltip ">
      <div class="annual-saving-header elipsiscss" >
        <div class="annual-saving-value-hover">
          <div class="wrapper-tooltip-header-message">${intl.formatMessage({ id: "label.annual_saving", defaultMessage: "Annual saving" })}
          </div>
        </div>
        ${intl.formatMessage({ id: "label.annual_saving", defaultMessage: "Annual saving" })}
      </div>
    </div>`;
  }

  function getAnnualSavingUnit(id: string, defaultMessage: string) {
    return `<div class="annual-saving-unit" > ${intl.formatMessage({ id: id, defaultMessage: defaultMessage })} </div> `;
  }

  function getCarbonPricing() {

    // return `${ getAnnualSavingHeader() } <div class="annual-saving-value" >
    //        ${data?.carbonPricing}
    //         </div>
    //         </div>  
    //         <div class="wrapper-tooltip "> 
    //         <div class="annual-saving-unit elipsiscss">
    //         <div class="annual-saving-value-hover">
    //           <div class="wrapper-tooltip-message">${intl.formatMessage({ id: "label.carbon_pricing", defaultMessage: "Carbon pricing" })}</div>
    //           ${intl.formatMessage({ id: "label.carbon_pricing", defaultMessage: "Carbon pricing" })}
    //         </div>
    //         <div class="sub-unit">(${intl.formatMessage({ id: "label.euro", defaultMessage: "Euro" })})
    //         </div> </div> </div> `;

    return `${getAnnualSavingHeader()} <div class="annual-saving-value" >
           ${data?.carbonPricing}
            </div> 
            <div class="wrapper-tooltip "> 
                <div class="annual-saving-unit elipsiscss" >
                  <div class="annual-saving-value-hover">
                    <div class="wrapper-tooltip-message">${intl.formatMessage({ id: "label.carbon_pricing", defaultMessage: "Carbon pricing" })}
                    </div>
                  </div>
                  ${intl.formatMessage({ id: "label.carbon_pricing", defaultMessage: "Carbon pricing" })}
                <div class="sub-unit">(${intl.formatMessage({ id: "label.euro", defaultMessage: "Euro" })})
                </div> 
                </div>
            </div>
            </div>`;
  }

  function getAnnualElectricityInEuro() {
    return `${getAnnualSavingHeader()} <div class="annual-saving-value" > 
            ${data?.annualElectricityInEuro}
            </div>
            ${getAnnualSavingUnit("label.euro", "Euro")}
            `;
  }

  function getDisplayName(currentPage: number) {
    switch (currentPage) {
      case 0:
        return getAnnualSaving();
      case 1:
        return getAnnualSavingInTon();
      case 2:
        return getCarbonPricing();
      case 3:
        return getAnnualElectricityInEuro();
      default:
        return getAnnualSaving();
    }
  }

  function getAssumptions(key: string) {
    return `<tr>
                <td><b>${intl.formatMessage({ id: "label.assumption", defaultMessage: "Assumption" })}</b></td>
              </tr>
              <tr>
                <td>
                  ${key == "preventiveMaintenance" ? intl.formatMessage({ id: "label.preventive_maintenance_assumption", defaultMessage: "Calculation of run-to-fail vs. preventive maintenance, statistically proven extension of a tools life time due to maintenance" }) :
        intl.formatMessage({ id: "label.tooltip_message_1", defaultMessage: "Calculation done as per" })
        + data[key].usagePerDay + intl.formatMessage({ id: "label.tooltip_message_2", defaultMessage: "hr/day" }) + ", "
        + data[key].operationDaysInYear + intl.formatMessage({ id: "label.tooltip_message_3", defaultMessage: "days" }) + ", "
        + data[key].numberOfTighteningPerDay
        + intl.formatMessage({ id: "label.tooltip_message_4", defaultMessage: "tightening/day with 80% of max. torque & 100 EUR/ton CO2e." })}
                </td>
              </tr>`;
    // <tr>
    //   <td>Operations days in a year : ${data[key].operationDaysInYear} days</td>
    // </tr>
    // <tr>
    //   <td>Number of tightening per day : ${data[key].numberOfTighteningPerDay} with 80% of max. torque & 100 EUR/ton CO2e.</td>
    // </tr> `;
    // ${getByKeyData(key)}`;
  }

  // function getByKeyData(key: string) {
  //   switch(key) {
  //     case 'airToElectric':
  //       return `<tr>
  //                 <td>Energy consumption Pneumatic tool - LTP : ${data[key].energyConsumptionPneumaticTool}</td>
  //               <tr/>`;
  //     case 'electricToElectric':
  //       return `<tr>
  //                 <td>Energy consumption Electric tool - ETV STB64 70 : ${data[key].energyConsumptionElectricTool}</td>
  //               <tr/>
  //               <tr>
  //                 <td>Energy consumption IXB tool- ITB A61 70 13 : ${data[key].energyConsumptionIxbTool}</td>
  //               <tr/>`;
  //     case 'controller':
  //       return `<tr>
  //                 <td>Energy consumption Controller - PF6000 : ${data[key].energyConsumptionController}</td>
  //               <tr/>`;
  //     default:
  //       return  `<tr>
  //                 <td>Energy consumption Pneumatic tool - LTP : ${data[key].energyConsumptionPneumaticTool}</td>
  //               <tr/>`;        
  //   }
  // }

  const value = {
    chart: {
      type: "pie",
      height: 450,
      backgroundColor: '#F3F3F3',
      animation: false
    },
    title: {
      verticalAlign: "middle",
      floating: true,
      text: `<div class='${isPageChange ? 'show' : ''}'>  ${getDisplayName(currentPage)}  </div>`,
      useHTML: true,
    },
    tooltip: {
      hideDelay: 0,
      outside: false,
      useHTML: true,
      positioner: function () {
        let x, y;
        // Set your desired fixed position here (e.g., fixed X and Y coordinates)
        x = 0; // Adjust this value to set the fixed X position
        y = -5; // Adjust this value to set the fixed Y position
        return { x, y };
      },
      formatter: function (): any {
        const point = this as unknown as CustomPoint;
        return `<table class="tooltip-table">
                  <tr>
                    <th>${point.key} </th>
                  </tr>
                    <tr><td class="tool-tip-percentage" style="color: ${point.color}"> ${point.y}% </td> 
                    </tr>
                  <tr>
                    <td class="tooltip-description-header"> ${point.point.options.descriptionHeader} </td>
                  </tr>
                  ${getAssumptions(point.point.options.key)}
                </table>`;
      },
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 350
          },
          chartOptions: {
            chart: {
              spacing: [0, 0, 0, 0],
              margin: [0, 0, 0, 0],
              height: 300
            }

          }

        }
      ]
    },

    plotOptions: {
      series: {
        stickyTracking: false,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
        accessibility: {
          enabled: true,
          announceNewData: {
            announcementFormatter: undefined,
            enabled: false,
            interruptUser: false,
            minAnnounceInterval: 5000
          },
          highContrastTheme: undefined,

          keyboardNavigation: {
            enabled: true,
            focusBorder: {
              enabled: true,
              hideBrowserFocusOutline: true,
              margin: 2,
              style: {
                borderRadius: 3,
                color: "#334eff",
                lineWidth: 2,
              },
            },
            order: undefined,
            seriesNavigation: {
              mode: "normal",
              pointNavigationEnabledThreshold: false,
              rememberPointFocus: false,
              skipNullPoints: true,
            },
            wrapAround: true,
          }
        },
        borderWidth: 0,
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '80%',
        dataLabels: {
          enabled: false,
          crop: false,
          distance: '-10%',
          style: {
            fontWeight: 'bold',
            fontSize: '16px'
          },
          connectorWidth: 0
        } as PlotSeriesDataLabelsOptions
      } as PlotSeriesOptions
    },
    colors: ['#008C95', '#43B02A', '#8DC8E8', '#0066B3'],
    series: [
      {
        data: [
          {
            y: data?.airToElectricRatio,
            key: "airToElectric",
            name: intl.formatMessage({ id: "label.air_electric", defaultMessage: "Air to electric" }),
            descriptionHeader: intl.formatMessage({ id: "label.air_to_electric_saving_description", defaultMessage: "Air to electric saving description" }),
          },
          {
            y: data?.savingFromControllerRatio,
            key: "controller",
            name: intl.formatMessage({ id: "label.controller", defaultMessage: "Controller" }),
            descriptionHeader: intl.formatMessage({ id: "label.controller_description", defaultMessage: "Description of how number of tools can affect saving" }),
          },
          {
            y: data?.electricToElectricRatio,
            key: "electricToElectric",
            name: intl.formatMessage({ id: "label.electric_to_electric", defaultMessage: "Electric to electric" }),
            descriptionHeader: intl.formatMessage({ id: "label.electric_to_electric_saving_description", defaultMessage: "Explain how tools with integrated controller affects the saving" }),
          },
          {
            y: data?.savingFromPreventiveMaintenanceRatio,
            key: "preventiveMaintenance",
            name: intl.formatMessage({ id: "label.preventive_maintenance", defaultMessage: "Preventive Maintenance" }),
            descriptionHeader: intl.formatMessage({ id: "label.preventive_maintenance_saving_description", defaultMessage: "Preventive Maintenance saving description" }),
          }
        ],
        size: "100%",
        // innerSize: "150%,",
      },
    ]
  };
  // Highcharts.wrap(Highcharts.Tooltip.prototype, 'hide', function(proceed) {});



  return (<>
    <div className='left-container'>
      <div className='position-relative custom-hightchart'>
        <HighchartsReact
          highcharts={Highcharts}
          options={value}
        />
      </div>
    </div>
  </>);
}
export default Chart;
