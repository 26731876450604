import { useEffect, useState } from "react";
import './pagination.scss';

function Pagination({ onChange }: any) {
    //const publicURL = process.env.REACT_APP_API_ENDPOINT;
    const [activeIndex, setActive] = useState(0);

    useEffect(() => {
        const interval = setIntervalCall() as any;
        return () => clearInterval(interval);
    }, [onChange]);

    function setIntervalCall() {
        const interval = setInterval(() => {
            const index = getActiveIndex(true);
            setActive(index);
            onChange(index);
        }, 7000);
        return interval;
    }

    const setActiveIndexController = (isNext: boolean) => {
        const index = getActiveIndex(isNext);
        setActive(index);
        onChange(index);
    }

    function getActiveIndex(isNext: boolean): number {
        let index;
        if (isNext) {
            if (activeIndex === 3) {
                index = 0;
            } else {
                index = activeIndex + 1;
            }
        } else {
            if (activeIndex === 0) {
                index = 3;
            } else {
                index = activeIndex - 1;
            }
        }
        return index;
    }

    const onIndivisualClickHandler = (index: number) => {
        setActive(index);
        onChange(index);
    }

    return (<>

        <div className='pagination-arrow'>
            <img className='img-arrow' src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/back-arrow.svg" alt="Icon for backward arrow" onClick={() => setActiveIndexController(false)} />
            <div className='pagination'>
                {Array.from({ length: 4 }).map((_, index) => {
                    return <div key={index} className={activeIndex === index ? 'active page page-1' : 'page page-1'}
                        onClick={() => onIndivisualClickHandler(index)}></div>;
                })}
            </div>
            <img className='img-arrow' src="https://ac-app-p-10007761-globalwebsites-co2.azurewebsites.net/assets/icons/front-arrow.svg" alt="Icon for forward arrow" onClick={() => setActiveIndexController(true)} />
        </div></>)
}
export default Pagination;