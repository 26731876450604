import Dashboard from './dashboard/dashboard';
export interface IDashboardProps{}; 
 

function Co2Components(){ 
    return(
        <> 
            <Dashboard/> 
        </>
    )
} 
export default Co2Components;